import React from 'react';
import { Button } from "./components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./components/ui/card";
import { Scale, Cookie, Percent } from 'lucide-react';

const Feature = ({ icon, title, description }) => (
  <Card className="w-full md:w-1/3 m-2">
    <CardHeader>
      <CardTitle className="flex items-center">
        {icon}
        <span className="ml-2">{title}</span>
      </CardTitle>
    </CardHeader>
    <CardContent>
      <CardDescription>{description}</CardDescription>
    </CardContent>
  </Card>
);

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">FoodCalcs</h1>
          <nav>
            <Button variant="ghost" className="mr-2">Features</Button>
            <Button variant="ghost" className="mr-2">About</Button>
            <Button variant="ghost">Contact</Button>
          </nav>
        </div>
      </header>

      <main className="flex-grow">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Calculate Your Recipe Balance
            </h2>
            <p className="mt-5 max-w-xl mx-auto text-xl text-gray-500">
              FoodCalcs helps professional bakers and confectioners perfect their recipes with precise balance calculations.
            </p>
            <div className="mt-8">
              <Button size="lg" className="mr-4">Get Started</Button>
              <Button variant="outline" size="lg">Learn More</Button>
            </div>
          </div>

          <div className="mt-20">
            <h3 className="text-2xl font-bold text-center mb-8">Our Features</h3>
            <div className="flex flex-wrap justify-center">
              <Feature 
                icon={<Scale className="h-6 w-6" />}
                title="Precise Ratios" 
                description="Calculate perfect ratios for ganache, caramel, and other delicate confections."
              />
              <Feature 
                icon={<Cookie className="h-6 w-6" />}
                title="Recipe Optimization" 
                description="Adjust and fine-tune your recipes for optimal texture and flavor."
              />
              <Feature 
                icon={<Percent className="h-6 w-6" />}
                title="Ingredient Percentages" 
                description="Easily convert between weights, volumes, and baker's percentages."
              />
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 text-center">
          <p>&copy; 2024 FoodCalcs. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
